<template>
  <relatorio :headers="headersRel" />
</template>
<script>
  import Relatorio from './Relatorio.vue'
  import { sortCurrency } from '../../utils/sorts'

  export default {
    components: {
      Relatorio,
    },
    data () {
      return {
      }
    },
    computed: {
      headersRel () {
        return [
          { align: 'right', sortable: true, text: 'Ordem', value: 'ordem', width: 'auto', sort: this.orderValue, report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Código', value: 'codigo', width: 'auto', floatingfilter: true, report: true, styleReport: { halign: 'right' } },
          { align: 'left', sortable: true, text: 'Descrição', value: 'descricao', width: 'auto', floatingfilter: true, report: true },
          { align: 'center', sortable: false, text: 'UN', value: 'unidade', width: 'auto', floatingfilter: true, report: true },
          { align: 'right', sortable: true, text: 'Quantidade', value: 'quantidade', width: 'auto', report: true, styleReport: { halign: 'right' } },
          { align: 'right', sortable: true, text: 'Valor Total (R$)', value: 'valor_total', width: 'auto', sort: sortCurrency, report: true, styleReport: { halign: 'right' }, styleXLSX: { type: 'number' } },
          { align: 'right', sortable: true, text: 'Perc.(%)', value: 'percentual', width: 'auto', sort: sortCurrency, floatingfilter: true, report: true, styleReport: { halign: 'right' }, styleXLSX: { type: 'number' } },
        ]
      },
      orderValue () {
        return (a, b) => {
          const regex = /°/g
          const valueA = parseInt(a.replace(regex, ''))
          const valueB = parseInt(b.replace(regex, ''))
          return valueA - valueB
        }
      },
    },
  }
</script>
